import React, { useState, useEffect } from 'react';
import { Col, Row, Table, Button } from 'reactstrap';
import AuthRequired from '../components/AuthRequired';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import Select from 'react-select';
import Pagination from '../components/Pagination';
import { displayError } from '../utilities';
import { GET_HOSPITALS, GET_ER_BOOKINGS } from '../queries';
import { useQuery, useLazyQuery } from 'react-apollo';

const itemsPerPage = 10;

const ERAppointments = () => {
  const [fromDate, setFromDate] = useState(moment());
  const [toDate, setToDate] = useState(moment());
  const hospitalId = parseInt(localStorage.getItem('DEFAULT_HOSPITAL_ID'));
  const hospitalName = localStorage.getItem('SELECTED_HOSPITAL_NAME');
  const [appointments, setAppointments] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedHospital, setSelectedHospital] = useState({
    name: hospitalName,
    id: hospitalId
  });

  const {
    data: hospitalsData,
    loading: hospitalsLoading,
    error: hospitalsError
  } = useQuery(GET_HOSPITALS, { skip: !selectedHospital.id });

  const [fetchAppointments, { loading: appointmentsLoading }] = useLazyQuery(
    GET_ER_BOOKINGS,
    {
      onCompleted: data => {
        if (data && data.getERBookingsFromUserService) {
          const bookings = data.getERBookingsFromUserService;
          const activeAppointments = bookings.active.map(appt => ({
            ...appt,
            status: 'Active'
          }));
          const dischargedAppointments = bookings.discharged.map(appt => ({
            ...appt,
            status: 'Discharged'
          }));
          setAppointments([...activeAppointments, ...dischargedAppointments]);
        }
      },
      onError: error => displayError('Something went wrong', error)
    }
  );

  useEffect(() => {
    handleFetchAppointments();
    // eslint-disable-next-line
  }, [selectedHospital]);

  const handleFetchAppointments = () => {
    fetchAppointments({
      variables: {
        hospitalId: selectedHospital.id,
        startDate: moment(fromDate)
          .startOf('day')
          .format('YYYY-MM-DD'),
        endDate: moment(toDate)
          .endOf('day')
          .format('YYYY-MM-DD')
      }
    });
  };

  const handlePageChange = pageNumber => {
    setCurrentPage(pageNumber);
  };

  const startIndex = (currentPage - 1) * itemsPerPage;
  const endIndex = startIndex + itemsPerPage;

  return (
    <AuthRequired pageName="dxapp">
      <div style={{ margin: '1rem' }}>
        <Row>
          <Col>
            <h1>ER Appointments</h1>
          </Col>
        </Row>
        <Row className="toolbar">
          <Col sm={6}>
            <label className="bold">Hospital:</label>
            <Select
              value={selectedHospital}
              placeholder="Select hospital"
              options={hospitalsData.hospitals}
              onChange={v => setSelectedHospital(v)}
              getOptionLabel={option => option.name}
              getOptionValue={option => option.id}
              isLoading={hospitalsLoading}
              isDisabled={hospitalsLoading || hospitalsError}
            />
          </Col>
        </Row>
        <Row className="toolbar">
          <Col sm={3}>
            <label className="bold">From Date:</label>
            <DatePicker
              selected={moment(fromDate)}
              maxDate={moment()}
              dateFormat="DD MMM YYYY"
              selectsStart
              startDate={moment(fromDate)}
              endDate={moment(toDate)}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              onChange={date => setFromDate(date)}
              className="reports-date-height"
            />
          </Col>
          <Col sm={3}>
            <label className="bold">To Date:</label>
            <div style={{ position: 'relative' }}>
              <DatePicker
                selected={moment(toDate)}
                maxDate={moment()}
                dateFormat="DD MMM YYYY"
                selectsStart
                startDate={moment(fromDate)}
                endDate={moment(toDate)}
                showMonthDropdown
                showYearDropdown
                dropdownMode="select"
                onChange={date => setToDate(date)}
                className="reports-date-height"
              />
            </div>
          </Col>
          <Col>
            <Button
              style={{ marginTop: '1.8rem' }}
              color="primary"
              onClick={handleFetchAppointments}
              disabled={appointmentsLoading}
            >
              {appointmentsLoading ? 'Loading...' : 'Get Appointments'}
            </Button>
          </Col>
        </Row>
        <Row className="toolbar">
          <Col style={{ marginTop: '1rem' }}>
            <Table size="sm" striped responsive>
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Slot Time</th>
                  <th>Patient&apos;s Name</th>
                  <th>Contact Number</th>
                  <th>MPID</th>
                  <th>Emergency Type</th>
                  <th>Appointment state</th>
                  <th>Status</th>
                  <th>Nurse Assessment Link</th>
                  <th>Prescription Link</th>
                </tr>
              </thead>
              <tbody>
                {appointments.length === 0 ? (
                  <tr>
                    <td colSpan="9" className="text-center bold">
                      No data available
                    </td>
                  </tr>
                ) : (
                  appointments
                    .slice(startIndex, endIndex)
                    .map((appointment, index) => (
                      <tr key={startIndex + index}>
                        <td>{startIndex + index + 1}</td>
                        <td>
                          {moment(appointment.slotTime).format(
                            'MM/DD/YYYY hh:mm A'
                          )}
                        </td>
                        <td>{appointment.userName}</td>
                        <td>{appointment.userMobile}</td>
                        <td>{appointment.mpid}</td>
                        <td>{appointment.emergencyType}</td>
                        <td>{appointment.appointmentState}</td>
                        <td>{appointment.status}</td>
                        <td>
                          {appointment.assessmentLink ? (
                            <a
                              href={appointment.assessmentLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Assessment
                            </a>
                          ) : (
                            'N/A'
                          )}
                        </td>
                        <td>
                          {appointment.prescriptionLink ? (
                            <a
                              href={appointment.prescriptionLink}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              Prescription
                            </a>
                          ) : (
                            'N/A'
                          )}
                        </td>
                      </tr>
                    ))
                )}
              </tbody>
            </Table>
          </Col>
        </Row>
        {appointments.length > 0 && (
          <Row className="toolbar">
            <Col className="pagination-container">
              <Pagination
                currentPage={currentPage}
                setCurrentPage={handlePageChange}
                totalPages={Math.ceil(appointments.length / itemsPerPage)}
              />
            </Col>
          </Row>
        )}
      </div>
    </AuthRequired>
  );
};

export default ERAppointments;
