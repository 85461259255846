import moment from 'moment';
import React, { Component } from 'react';
import { Query } from 'react-apollo';
import { AiTwotoneExperiment } from 'react-icons/ai';
import {
  FaBed,
  FaBolt,
  FaFilter,
  FaList,
  FaPrint,
  FaReadme,
  FaSearch,
  FaTv,
  FaXRay,
  FaNotesMedical,
  FaRegHospital
} from 'react-icons/fa';
import { FiGrid } from 'react-icons/fi';
import { FaRupeeSign } from 'react-icons/fa';
import { GiMedicines, GiMoneyStack } from 'react-icons/gi';
import Drawer from 'react-motion-drawer';
import {
  Button,
  Col,
  Container,
  Input,
  InputGroup,
  InputGroupAddon,
  Row
} from 'reactstrap';
import AppliedFilterText from '../components/AppliedFilterText';
import AppointmentList from '../components/AppointmentList';
import ApptSearchFormV2 from '../components/ApptSearchFormV2';
import BookingInfoModal from '../components/BookingInfoModal';
import BookingModal from '../components/BookingModal';
import { BookingStatusTabs } from '../components/BookingStatusTabs';
import CustomerDetailsModal from '../components/CustomerDetailsModal';
import CustomerRxModal from '../components/CustomerRxModal';
import LabsAndScansPrescribedModal from '../components/LabsAndScansPrescribedModal';
import Loader from '../components/Loader';
import PrintBookingsModal from '../components/PrintBookingsModal';
import Tooltip from '../components/Tooltip';
import { isPermitted, permissions } from '../permissions';
import {
  GET_ALL_HOSPITALS,
  LOCAL_USER_HOSPITALS,
  SEARCH_BOOKINGS,
  SEARCH_BOOKINGS_POLLING_INTERVAL
} from '../queries';
import {
  displayError,
  logAmplitudeEvent,
  setDefaultHospitalId
} from '../utilities';
import { NavLink } from 'react-router-dom';
import BedAndPackageBookingModal from '../components/BedAndPackageBookingModal';
import CreateInvoiceModal from '../components/CreateInvoiceModal';
import CreateScanInvoiceModal from '../components/CreateScanInvoiceModal';
import DocListApptPage from '../components/DocListApptPage';
import EmergencyBookingModal from '../components/EmergencyBookingModal';
import FunnyNoAppointmentMessage from '../components/FunnyNoAppointmentMessage';
import LabBookingModal from '../components/LabBookingModal';
import OtherServicesBookingModal from '../components/OtherServicesBookingModal';
import PharmaBillingModal from '../components/PharmaBillingModal';
import QuickBookingModal from '../components/QuickBookingModal';
import RegistrationModal from '../components/RegistrationModal';
import ScanBookingModal from '../components/ScanBookingModal';
import ShowBookingsModal from '../components/ShowBookingsModal';
import ApptFilter, {
  APPT_FILTER,
  sortBooking
} from '../components/SortFilterAppt';
import TVDisplayModal from '../components/TVDisplayModal';
import UpdateMpidModal from '../components/UpdateMpidModal';
import { getLoggedInUser } from '../permissions';
import DoctorChargesModal from '../components/DoctorChargesModal';

// TODO: Enable it later
// import { LatestSlotRow } from '../components/LatestSlotRow';

export default class ApptInner extends Component {
  state = {
    isBookingOpen: false,
    showPrintModal: false,
    bookDoc: undefined,
    searchCriteria: {
      date: moment().toDate(),
      departments: [],
      objectIds: [],
      procedureNames: [],
      languageNames: [],
      appointmentState: undefined
    },
    typedPhoneNumber: undefined,
    drawerOpen: false,
    showBookingInfoModal: false,
    bookingIdForInfoModal: null,
    mpidForInfoModal: null,
    showBookingHistory: false,
    apptFilter: APPT_FILTER.slotTime,
    isQuickBookingOpen: false,
    isLabBookingOpen: false,
    isOtherServicesBookingOpen: false,
    isScanBookingOpen: false,
    isBedAndPackageBookingOpen: false,
    isPharmaBillingOpen: false,
    isEmergencyBookingOpen: false,
    emergencyBookDoc: undefined,
    emergencyBookDocEntityId: undefined,
    bookingIdForCreateInvoiceModal: undefined,
    doctorIdForCreateInvoiceModal: undefined,
    showCreateInvoiceModal: false,
    bookingIdForCreateScanInvoiceModal: undefined,
    doctorIdForCreateScanInvoiceModal: undefined,
    showCreateScanInvoiceModal: false,
    bookingIdForUpdateMpidModal: undefined,
    showUpdateMpidModal: false,
    showCustomerRxModal: false,
    userMobileForRxModal: null,
    userMPIDForRxModal: null,
    userMPIDForInfoModal: null,
    isRegistrationOpen: false,
    isDoctorChargesOpen: false,
    isTvDisplayOpen: false,
    showCustomerDetailsModal: false,
    userMPIDForCustomerModal: null,
    showLabsAndScansPrescribedModal: false,
    userMPIDForLabsAndScansModal: null,
    sitecodeForLabsAndScansModal: null,
    appointmentIdForLabsAndScansModal: null,
    doctorIdForLabsAndScansModal: null,
    selectedHospitalDetails: null,
    allHospitalDetails: null,
    allDoctorDetails: null,
    showBookingModal: false
  };
  userRole = getLoggedInUser().role;
  setApptFilter = apptFilter => this.setState({ apptFilter });

  toggleBookingModal = () => {
    const { isBookingOpen } = this.state;
    this.setState({ isBookingOpen: !isBookingOpen });
  };

  bookModal = (e, docId, entityId) => {
    // console.log(`docId: ${docId}`);
    e.preventDefault();
    this.setState({
      bookDoc: docId,
      isBookingOpen: true,
      entityId
    });
  };

  doctorArray = doctorDetails => {
    this.allDoctorDetails = doctorDetails;
  };

  bookModalWithoutEvent = (docId, entityId) => {
    this.setState({
      bookDoc: docId,
      isBookingOpen: true,
      entityId
    });
  };

  handleFilterChange = filters => {
    // console.log('Filters: ' + JSON.stringify(filters));
    this.setState({
      searchCriteria: filters,
      drawerOpen: false
    });
  };

  kickoffBackendSearch = () => {
    const { typedPhoneNumber } = this.state;
    if (!typedPhoneNumber || !/^\d{10}$/.test(typedPhoneNumber)) {
      displayError('Phone number should have a 10 digits');
      return;
    }
    this.setState({ showBookingHistory: true });
  };

  setPhoneNumber = e => {
    if (this.state.showBookingHistory) {
      this.setState({ showBookingHistory: false });
    }
    const typedPhoneNumber = e.target.value.trim();

    //NOTE: THIS IS EXPENSIVE OPERATION BECAUSE IT RERENDERS THE LARGE LIST
    //LETS DONT FILTER WHEN NOT REQUIRED
    if (typedPhoneNumber.length < 1) {
      this.setState({ typedPhoneNumber: undefined });
      return;
    }

    if (typedPhoneNumber.length < 3) {
      return;
    }
    this.setState({ typedPhoneNumber });

    // If you want to search in backend on enter
    if (e.key === 'Enter') {
      this.kickoffBackendSearch();
    }
  };

  tabSelected = appointmentState => {
    const { searchCriteria } = this.state;

    this.setState({
      ...this.state,
      searchCriteria: { ...searchCriteria, appointmentState }
    });
  };

  getDocSearchCriteria = selectedHospitals => {
    const {
      searchCriteria: {
        departments,
        objectIds,
        date,
        languageNames,
        procedureNames
      }
    } = this.state;
    const hospitalIds =
      selectedHospitals && selectedHospitals.length > 0
        ? selectedHospitals
        : [];

    const departmentIds =
      departments && departments.length > 0 ? departments : [];

    const doctorIds = objectIds && objectIds.length > 0 ? objectIds : [];
    const languages =
      languageNames && languageNames.length > 0 ? languageNames : [];
    const procedures =
      procedureNames && procedureNames.length > 0 ? procedureNames : [];

    return {
      hospitalIds,
      departmentIds,
      doctorIds,
      date,
      procedures,
      languages
    };
  };

  togglePrintModal = () => {
    this.setState({
      showPrintModal: !this.state.showPrintModal
    });
    logAmplitudeEvent('Print_Appointments_Tab_Pressed', {}, true);
  };

  toggleBookingListModal = () => {
    if (!this.state.showBookingModal)
      logAmplitudeEvent('Show_Bookings_Button_Pressed', {}, true);
    this.setState({
      showBookingModal: !this.state.showBookingModal
    });
  };

  toggleQuickBooking = () => {
    if (!this.state.isQuickBookingOpen)
      logAmplitudeEvent('QuickBooking_Button_Pressed', {}, true);
    this.setState({
      isQuickBookingOpen: !this.state.isQuickBookingOpen
    });
  };

  toggleLabBooking = () => {
    if (!this.state.isLabBookingOpen)
      logAmplitudeEvent('Lab_Booking_Button_Pressed', {}, true);
    this.setState({
      isLabBookingOpen: !this.state.isLabBookingOpen
    });
  };

  toggleOtherServicesBooking = () => {
    if (!this.state.isOtherServicesBookingOpen)
      logAmplitudeEvent('Other_Services_Booking_Button_Pressed', {}, true);
    this.setState({
      isOtherServicesBookingOpen: !this.state.isOtherServicesBookingOpen
    });
  };

  toggleScanBooking = () => {
    if (!this.state.isScanBookingOpen)
      logAmplitudeEvent('Scan_Booking_Button_Pressed', {}, true);
    this.setState({
      isScanBookingOpen: !this.state.isScanBookingOpen
    });
  };

  toggleBedAndPackageBooking = () => {
    if (!this.state.isBedAndPackageBookingOpen)
      logAmplitudeEvent('Bed_Booking_Button_Pressed', {}, true);
    this.setState({
      isBedAndPackageBookingOpen: !this.state.isBedAndPackageBookingOpen
    });
  };

  togglePharmaBilling = () => {
    if (!this.state.isPharmaBillingOpen)
      logAmplitudeEvent('Pharma_Billing_Button_Pressed', {}, true);
    this.setState({
      isPharmaBillingOpen: !this.state.isPharmaBillingOpen
    });
  };

  toggleRegistration = () => {
    if (!this.state.isRegistrationOpen)
      logAmplitudeEvent('Registration_Button_Pressed', {}, true);
    this.setState({
      isRegistrationOpen: !this.state.isRegistrationOpen
    });
  };

  toggleDoctorCharges = () => {
    if (!this.state.isDoctorChargesOpen)
      logAmplitudeEvent('Doctor_charges_Button_Pressed', {}, true);
    this.setState({
      isDoctorChargesOpen: !this.state.isDoctorChargesOpen
    });
  };

  toggleTvDisplay = () => {
    if (!this.state.isTvDisplayOpen)
      logAmplitudeEvent('TV_Display_Button_Pressed', {}, true);
    this.setState({
      isTvDisplayOpen: !this.state.isTvDisplayOpen
    });
  };

  emergencyBookModal = (e, docId, entityId) => {
    // console.log(`docId: ${docId}`);
    e.preventDefault();
    this.setState({
      emergencyBookDoc: docId,
      isEmergencyBookingOpen: true,
      emergencyBookDocEntityId: entityId
    });
  };

  toggleEmergencyBooking = () => {
    const { isEmergencyBookingOpen } = this.state;
    this.setState({
      isEmergencyBookingOpen: !isEmergencyBookingOpen
    });
  };

  toggleBookingInfoModal = () =>
    this.setState({
      showBookingInfoModal: !this.state.showBookingInfoModal
    });

  setBookingIdForInfoModal = (bookingIdForInfoModal, mpidForInfoModal) => {
    // console.log(`bookngid: ${bookingIdForInfoModal}`);
    this.setState({
      bookingIdForInfoModal,
      mpidForInfoModal,
      showBookingInfoModal: true
    });
  };

  setDataForCreateInvoiceModal = (bookingId, doctorId) => {
    this.setState({
      bookingIdForCreateInvoiceModal: bookingId,
      doctorIdForCreateInvoiceModal: doctorId,
      showCreateInvoiceModal: true
    });
  };

  setDataForCreateScanInvoiceModal = (bookingId, doctorId) => {
    this.setState({
      bookingIdForCreateScanInvoiceModal: bookingId,
      doctorIdForCreateScanInvoiceModal: doctorId,
      showCreateScanInvoiceModal: true
    });
  };

  toggleCreateInvoiceModal = () => {
    this.setState({
      showCreateInvoiceModal: !this.state.showCreateInvoiceModal
    });
  };

  toggleCreateScanInvoiceModal = () => {
    this.setState({
      showCreateScanInvoiceModal: !this.state.showCreateScanInvoiceModal
    });
  };

  setDataForUpdateMpidModal = bookingId => {
    this.setState({
      bookingIdForUpdateMpidModal: bookingId,
      showUpdateMpidModal: true
    });
  };

  toggleUpdateMpidModal = () => {
    this.setState({
      showUpdateMpidModal: !this.state.showUpdateMpidModal
    });
  };

  toggleCustomerRxModal = () =>
    this.setState({
      showCustomerRxModal: !this.state.showCustomerRxModal
    });

  toggleCustomerDetailsModal = () =>
    this.setState({
      showCustomerDetailsModal: !this.state.showCustomerDetailsModal
    });

  toggleLabsAndScansPrescribedModal = () =>
    this.setState({
      showLabsAndScansPrescribedModal: !this.state
        .showLabsAndScansPrescribedModal
    });

  setUserDetailsForRxModal = (userMobileForRxModal, userMPIDForRxModal) => {
    this.setState({
      userMobileForRxModal,
      userMPIDForRxModal,
      showCustomerRxModal: true
    });
  };

  setDetailsForCustomerModal = userMPIDForCustomerModal => {
    this.setState({
      userMPIDForCustomerModal,
      showCustomerDetailsModal: true
    });
  };

  setDetailsForLabsAndScansPrescribedModal = (
    userMPIDForLabsAndScansModal,
    sitecodeForLabsAndScansModal,
    appointmentIdForLabsAndScansModal,
    doctorIdForLabsAndScansModal
  ) => {
    this.setState({
      userMPIDForLabsAndScansModal,
      sitecodeForLabsAndScansModal,
      appointmentIdForLabsAndScansModal,
      doctorIdForLabsAndScansModal,
      showLabsAndScansPrescribedModal: true
    });
  };

  render() {
    const {
      bookDoc,
      entityId,
      isBookingOpen,
      showPrintModal,
      searchCriteria,
      typedPhoneNumber,
      drawerOpen,
      showBookingHistory,
      apptFilter,
      isQuickBookingOpen,
      isLabBookingOpen,
      isOtherServicesBookingOpen,
      isScanBookingOpen,
      isBedAndPackageBookingOpen,
      isPharmaBillingOpen,
      isEmergencyBookingOpen,
      emergencyBookDoc,
      emergencyBookDocEntityId,
      showCreateInvoiceModal,
      bookingIdForCreateInvoiceModal,
      doctorIdForCreateInvoiceModal,
      showCreateScanInvoiceModal,
      bookingIdForCreateScanInvoiceModal,
      doctorIdForCreateScanInvoiceModal,
      showUpdateMpidModal,
      bookingIdForUpdateMpidModal,
      isRegistrationOpen,
      isDoctorChargesOpen,
      // isTvDisplayOpen,
      showBookingModal
    } = this.state;

    const { date } = searchCriteria;

    return (
      <Query query={LOCAL_USER_HOSPITALS}>
        {({ data: { selectedHospitals, allHospitals } = {} }) => {
          const selectedHospitalId = selectedHospitals[0];
          const selectedHospitalObj = allHospitals.find(
            ({ id }) => selectedHospitalId === id
          );
          setDefaultHospitalId(selectedHospitalId);
          const hospitalName = selectedHospitalObj
            ? selectedHospitalObj.name
            : 'No hospital selected';

          const docSearchCriteria = this.getDocSearchCriteria(
            selectedHospitals
          );

          let searchInput = {
            ...searchCriteria,
            hospitals: selectedHospitals
          };

          if (showBookingHistory) {
            searchInput = { mobile: typedPhoneNumber };
          }

          return (
            <Container fluid>
              <Drawer
                open={drawerOpen}
                width={300}
                onChange={open => this.setState({ drawerOpen: open })}
                drawerStyle={{
                  background: '#F9F9F9',
                  boxShadow:
                    'rgba(0, 0, 0, 0.188235) 0px 10px 20px, rgba(0, 0, 0, 0.227451) 0px 6px 6px'
                }}
                noTouchOpen
                noTouchClose
              >
                <Row style={{ margin: 0, padding: 0 }}>
                  <Col>
                    <h3>Filters</h3>
                    <ApptSearchFormV2 onFilter={this.handleFilterChange} />
                  </Col>
                </Row>
              </Drawer>
              <Row>
                <Col
                  sm={3}
                  style={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Button
                    color="link"
                    style={{
                      color: 'black',
                      marginRight: '1rem'
                    }}
                    onClick={() => this.setState({ drawerOpen: true })}
                    id="filterappts"
                  >
                    <FaFilter className="zoom" />
                  </Button>
                  <Tooltip
                    target="filterappts"
                    message="Filter Appointments"
                    placement="bottom"
                  />
                  <h1 style={{ display: 'inline' }}>Appointments </h1>
                </Col>
                <Col sm={3}>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'horizontal',
                      paddingTop: '0.5rem'
                    }}
                  >
                    <InputGroup>
                      <Input
                        placeholder="Search"
                        onKeyUp={this.setPhoneNumber}
                        maxLength={10}
                      />
                      <InputGroupAddon addonType="append">
                        <Button onClick={this.kickoffBackendSearch}>
                          <FaSearch />
                        </Button>
                      </InputGroupAddon>
                    </InputGroup>
                  </div>
                </Col>
                <Col
                  sm={6}
                  md={5}
                  lg={4}
                  className="text-right"
                  style={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                    alignItems: 'center'
                  }}
                >
                  <div>
                    <NavLink to="/dxapp">
                      <Button
                        color="link"
                        // onClick={this.toggleRegistration}
                        id="dxAppDashboard"
                      >
                        <FaNotesMedical className="zoom" />
                      </Button>
                      <Tooltip
                        target="dxAppDashboard"
                        message="DxApp Dashboard"
                        placement="right"
                      />
                    </NavLink>
                  </div>
                  {(this.userRole === 'admin' || this.userRole === 'gre') && (
                    <>
                      <div>
                        <NavLink to="/coupons">
                          <Button
                            color="link"
                            // onClick={this.toggleTvDisplay}
                            id="Coupons"
                          >
                            <GiMoneyStack className="coupon zoom" />
                          </Button>
                          <Tooltip
                            target="Coupons"
                            message="Coupons"
                            placement="right"
                          />
                        </NavLink>
                      </div>
                      <div>
                        <Button
                          color="link"
                          onClick={this.toggleTvDisplay}
                          id="TvDisplay"
                        >
                          <FaTv className="zoom" />
                        </Button>
                        <Tooltip
                          target="TvDisplay"
                          message="TV Display"
                          placement="right"
                        />
                      </div>
                    </>
                  )}
                  {
                    <Query
                      query={GET_ALL_HOSPITALS}
                      variables={{ showAll: true }}
                    >
                      {({ data }) => {
                        let selectedHospObj;
                        if (data && data.hospitals) {
                          this.allHospitalDetails = data;
                          selectedHospObj = data.hospitals.find(
                            ({ id }) => selectedHospitalId === id
                          );
                        }
                        this.selectedHospitalDetails = selectedHospObj;
                        return (
                          <>
                            {selectedHospObj &&
                            (selectedHospObj.chargeMaster === 'zoho_books' ||
                              !selectedHospObj.isCloudnineHosp) ? (
                              <>
                                {!selectedHospObj.isCloudnineHosp ? (
                                  <>
                                    <div>
                                      <Button
                                        color="link"
                                        id="PharmaBilling"
                                        onClick={this.togglePharmaBilling}
                                      >
                                        <GiMedicines className="zoom" />
                                      </Button>
                                      <Tooltip
                                        target="PharmaBilling"
                                        message="Pharma Billing"
                                        placement="right"
                                      />
                                    </div>
                                    <div>
                                      <Button
                                        color="link"
                                        id="BedAndPackageBooking"
                                        onClick={
                                          this.toggleBedAndPackageBooking
                                        }
                                      >
                                        <FaBed className="zoom" />
                                      </Button>
                                      <Tooltip
                                        target="BedAndPackageBooking"
                                        message="Bed And Package Booking"
                                        placement="right"
                                      />
                                    </div>
                                  </>
                                ) : null}
                                <div>
                                  <Button
                                    color="link"
                                    id="LabBooking"
                                    onClick={this.toggleLabBooking}
                                  >
                                    <AiTwotoneExperiment className="zoom" />
                                  </Button>
                                  <Tooltip
                                    target="LabBooking"
                                    message="Lab Booking"
                                    placement="right"
                                  />
                                </div>
                                <div>
                                  <Button
                                    color="link"
                                    id="ScanBooking"
                                    onClick={this.toggleScanBooking}
                                  >
                                    <FaXRay className="zoom" />
                                  </Button>
                                  <Tooltip
                                    target="ScanBooking"
                                    message="Scan Booking"
                                    placement="right"
                                  />
                                </div>
                                {selectedHospObj &&
                                selectedHospObj.siteCode === 'BJH' ? (
                                  <div>
                                    <Button
                                      color="link"
                                      onClick={this.toggleOtherServicesBooking}
                                      id="otherServices"
                                    >
                                      <FiGrid className="zoom" />
                                    </Button>
                                    <Tooltip
                                      target="otherServices"
                                      message="Other service booking"
                                      placement="right"
                                    />
                                  </div>
                                ) : null}
                              </>
                            ) : (
                              <div></div>
                            )}
                          </>
                        );
                      }}
                    </Query>
                  }
                  <div>
                    <Button
                      color="link"
                      onClick={this.toggleRegistration}
                      id="registration"
                    >
                      <FaReadme className="zoom" />
                    </Button>
                    <Tooltip
                      target="registration"
                      message="Register Here"
                      placement="right"
                    />
                  </div>
                  {isPermitted(permissions.VIEW_QUICK_APPOINTMENT_BOOK) && (
                    <div>
                      <Button
                        color="link"
                        onClick={this.toggleQuickBooking}
                        id="quickbook"
                      >
                        <FaBolt className="zoom" />
                      </Button>
                      <Tooltip
                        target="quickbook"
                        message="Book Appointment"
                        placement="right"
                      />
                    </div>
                  )}
                  {isPermitted(permissions.VIEW_QUICK_APPOINTMENT_BOOK) && (
                    <div>
                      <Button
                        color="link"
                        onClick={this.togglePrintModal}
                        id="printlist"
                      >
                        <FaPrint className="zoom" />
                      </Button>
                      <Tooltip
                        target="printlist"
                        message="Print Appointments"
                        placement="right"
                      />
                    </div>
                  )}
                  {
                    <Query
                      query={GET_ALL_HOSPITALS}
                      variables={{ showAll: true }}
                    >
                      {({ data }) => {
                        let selectedHospObj;
                        if (data && data.hospitals) {
                          this.allHospitalDetails = data;
                          selectedHospObj = data.hospitals.find(
                            ({ id }) => selectedHospitalId === id
                          );
                        }
                        this.selectedHospitalDetails = selectedHospObj;
                        return (
                          <>
                            {' '}
                            {selectedHospObj &&
                            (selectedHospObj.chargeMaster === 'zoho_books' ||
                              !selectedHospObj.isCloudnineHosp) ? (
                              <div>
                                <Button
                                  color="link"
                                  onClick={this.toggleBookingListModal}
                                  id="booklist"
                                >
                                  <FaList className="zoom" />
                                </Button>
                                <Tooltip
                                  target="booklist"
                                  message="Show Bookings"
                                  placement="right"
                                />
                              </div>
                            ) : (
                              <div></div>
                            )}
                          </>
                        );
                      }}
                    </Query>
                  }
                  <div>
                    <Button
                      color="link"
                      onClick={this.toggleDoctorCharges}
                      id="doctorCharges"
                    >
                      <FaRupeeSign className="zoom" />
                    </Button>
                    <Tooltip
                      target="doctorCharges"
                      message="Doctor Charges"
                      placement="right"
                    />
                  </div>
                  <div>
                    <NavLink to="/er">
                      <Button color="link" id="erAppointments">
                        <FaRegHospital className="zoom" />
                      </Button>
                      <Tooltip
                        target="erAppointments"
                        message="ER Appointments"
                        placement="right"
                      />
                    </NavLink>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col sm={9}>
                  <AppliedFilterText
                    searchCriteria={{
                      ...searchCriteria,
                      hospitals: selectedHospitals
                    }}
                  />
                  <BookingStatusTabs
                    variables={{
                      input: {
                        ...searchCriteria,
                        hospitals: selectedHospitals
                      }
                    }}
                    onSelect={this.tabSelected}
                    selected={searchCriteria.appointmentState}
                  />
                  <Query
                    query={SEARCH_BOOKINGS}
                    variables={{
                      input: searchInput
                    }}
                    fetchPolicy="network-only"
                    pollInterval={SEARCH_BOOKINGS_POLLING_INTERVAL}
                    notifyOnNetworkStatusChange
                  >
                    {({
                      data: { getBookings } = {},
                      networkStatus,
                      stopPolling,
                      startPolling,
                      loading: loadingBookings
                    }) => {
                      const statusBar = (
                        <Row>
                          <Col>
                            {networkStatus < 7 ? <Loader size={5} /> : null}
                          </Col>
                          <Col
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end'
                            }}
                          >
                            <ApptFilter
                              onSelect={this.setApptFilter}
                              filter={apptFilter}
                            />
                          </Col>
                        </Row>
                      );

                      const isFilterByPhoneHasData =
                        typedPhoneNumber && typedPhoneNumber.trim().length > 0;
                      const hasServerReturnedAnyAppts =
                        getBookings && getBookings.length > 0;

                      let dataDisplay;
                      if (
                        !isFilterByPhoneHasData &&
                        !hasServerReturnedAnyAppts
                      ) {
                        dataDisplay = (
                          <FunnyNoAppointmentMessage
                            appointmentState={searchCriteria.appointmentState}
                          />
                        );
                      } else {
                        let filteredBooking = getBookings;
                        if (isFilterByPhoneHasData) {
                          // if name or phone matches
                          filteredBooking = getBookings.filter(
                            b =>
                              b.userMobile.indexOf(typedPhoneNumber) > -1 ||
                              b.userName
                                .toLowerCase()
                                .indexOf(typedPhoneNumber.toLowerCase()) > -1
                          );
                        }

                        filteredBooking = sortBooking(
                          filteredBooking,
                          apptFilter,
                          date
                        );

                        const noBookingFound =
                          !filteredBooking || filteredBooking.length < 1;

                        if (!showBookingHistory && noBookingFound) {
                          // dataDisplay = (
                          //   <LoadBookingFromQW
                          //     phone={typedPhoneNumber}
                          //     date={date}
                          //     displayDetail={this.setBookingIdForInfoModal}
                          //   />
                          // );
                        } else {
                          const noFilteredRes =
                            !filteredBooking || filteredBooking.length < 1;
                          if (
                            showBookingHistory &&
                            noFilteredRes &&
                            !loadingBookings
                          ) {
                            dataDisplay = (
                              <h6
                                className="text-muted"
                                style={{ textAlign: 'center' }}
                              >
                                No records found. May be we are looking for the
                                non-existent?
                              </h6>
                            );
                          } else {
                            dataDisplay = (
                              <AppointmentList
                                apptList={filteredBooking}
                                searchPhone={typedPhoneNumber}
                                displayDetail={this.setBookingIdForInfoModal}
                                stopPolling={stopPolling}
                                startPolling={startPolling}
                                onBook={this.bookModalWithoutEvent}
                                createInvoice={
                                  this.setDataForCreateInvoiceModal
                                }
                                createScanInvoice={
                                  this.setDataForCreateScanInvoiceModal
                                }
                                updateMpid={this.setDataForUpdateMpidModal}
                                customerRxDetails={
                                  this.setUserDetailsForRxModal
                                }
                                customerPersonalDetails={
                                  this.setDetailsForCustomerModal
                                }
                                labsAndScansPrescribed={
                                  this.setDetailsForLabsAndScansPrescribedModal
                                }
                              />
                            );
                          }
                        }
                      }
                      return (
                        <>
                          {statusBar}
                          {showBookingHistory && (
                            <h4
                              className="text-muted"
                              style={{ textAlign: 'center' }}
                            >
                              Showing booking history of {typedPhoneNumber}
                            </h4>
                          )}
                          {dataDisplay}
                        </>
                      );
                    }}
                  </Query>
                  <BookingInfoModal
                    isOpen={this.state.showBookingInfoModal}
                    toggle={this.toggleBookingInfoModal}
                    bookingId={this.state.bookingIdForInfoModal}
                    mpid={this.state.mpidForInfoModal}
                  />
                  <CustomerRxModal
                    isOpen={this.state.showCustomerRxModal}
                    toggle={this.toggleCustomerRxModal}
                    userMobile={this.state.userMobileForRxModal}
                    userMPID={this.state.userMPIDForRxModal}
                  />
                  <CustomerDetailsModal
                    isOpen={this.state.showCustomerDetailsModal}
                    toggle={this.toggleCustomerDetailsModal}
                    userMPID={this.state.userMPIDForCustomerModal}
                  />
                  <LabsAndScansPrescribedModal
                    isOpen={this.state.showLabsAndScansPrescribedModal}
                    toggle={this.toggleLabsAndScansPrescribedModal}
                    userMPID={this.state.userMPIDForLabsAndScansModal}
                    sitecode={this.state.sitecodeForLabsAndScansModal}
                    appointmentId={this.state.appointmentIdForLabsAndScansModal}
                    doctorId={this.state.doctorIdForLabsAndScansModal}
                  />
                </Col>
                <Col sm={3}>
                  {isPermitted(permissions.VIEW_QUICK_APPOINTMENT_BOOK) && (
                    <DocListApptPage
                      onStartBooking={this.bookModal}
                      onStartEmergencyBooking={this.emergencyBookModal}
                      listOfDoctors={this.doctorArray}
                      searchInput={docSearchCriteria}
                    />
                  )}
                </Col>
              </Row>
              <TVDisplayModal
                isOpen={this.state.isTvDisplayOpen}
                toggle={this.toggleTvDisplay}
                selectedHospitalDetails={this.selectedHospitalDetails}
                allDoctorDetails={this.allDoctorDetails}
              />
              <BookingModal
                doctorId={bookDoc}
                entityId={entityId}
                hospitalId={selectedHospitalId}
                hospitalName={hospitalName}
                isOpen={isBookingOpen}
                toggle={this.toggleBookingModal}
              />
              <QuickBookingModal
                isOpen={isQuickBookingOpen}
                toggle={this.toggleQuickBooking}
              />
              <LabBookingModal
                isOpen={isLabBookingOpen}
                toggle={this.toggleLabBooking}
                selectedHospitalDetails={this.selectedHospitalDetails}
                allDoctorDetails={this.allDoctorDetails}
              />
              <OtherServicesBookingModal
                isOpen={isOtherServicesBookingOpen}
                toggle={this.toggleOtherServicesBooking}
                selectedHospitalDetails={this.selectedHospitalDetails}
                allDoctorDetails={this.allDoctorDetails}
              />
              <ScanBookingModal
                isOpen={isScanBookingOpen}
                toggle={this.toggleScanBooking}
                selectedHospitalDetails={this.selectedHospitalDetails}
                allHospitalDetails={this.allHospitalDetails}
                allDoctorDetails={this.allDoctorDetails}
              />
              <BedAndPackageBookingModal
                isOpen={isBedAndPackageBookingOpen}
                toggle={this.toggleBedAndPackageBooking}
                selectedHospitalDetails={this.selectedHospitalDetails}
              />
              <PharmaBillingModal
                isOpen={isPharmaBillingOpen}
                toggle={this.togglePharmaBilling}
                selectedHospitalDetails={this.selectedHospitalDetails}
                allHospitalDetails={this.allHospitalDetails}
                allDoctorDetails={this.allDoctorDetails}
              />
              <RegistrationModal
                selectedHospitalDetails={this.selectedHospitalDetails}
                isOpen={isRegistrationOpen}
                toggle={this.toggleRegistration}
              />
              <EmergencyBookingModal
                doctorId={emergencyBookDoc}
                entityId={emergencyBookDocEntityId}
                hospitalId={selectedHospitalId}
                hospitalName={hospitalName}
                isOpen={isEmergencyBookingOpen}
                toggle={this.toggleEmergencyBooking}
              />
              <CreateInvoiceModal
                isOpen={showCreateInvoiceModal}
                toggle={this.toggleCreateInvoiceModal}
                selectedHospitalDetails={this.selectedHospitalDetails}
                doctorId={doctorIdForCreateInvoiceModal}
                bookingId={bookingIdForCreateInvoiceModal}
              />
              <CreateScanInvoiceModal
                isOpen={showCreateScanInvoiceModal}
                toggle={this.toggleCreateScanInvoiceModal}
                doctorId={doctorIdForCreateScanInvoiceModal}
                bookingId={bookingIdForCreateScanInvoiceModal}
              />
              <UpdateMpidModal
                isOpen={showUpdateMpidModal}
                toggle={this.toggleUpdateMpidModal}
                bookingId={bookingIdForUpdateMpidModal}
              />
              <PrintBookingsModal
                isOpen={showPrintModal}
                toggle={this.togglePrintModal}
                searchCriteria={searchCriteria}
                selectedHospitals={selectedHospitals}
              />
              <ShowBookingsModal
                isOpen={showBookingModal}
                toggle={this.toggleBookingListModal}
                hospitalId={selectedHospitalId}
                selectedHospitalDetails={this.selectedHospitalDetails}
              />
              <DoctorChargesModal
                isOpen={isDoctorChargesOpen}
                toggle={this.toggleDoctorCharges}
                selectedHospitalDetails={this.selectedHospitalDetails}
              />
            </Container>
          );
        }}
      </Query>
    );
  }
}
